import { memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { determineVariantCopy } from '@utils/shopify';
import { Chevron, Flex, Heading, Paragraph } from '@components';
import { OrderPOMS, OrderStatusPOMS } from '@ts/poms/orders';
import styles from './HistoryItem.module.scss';

type HistoryItemProps = {
	order: OrderPOMS;
	handleItemClick: (id: string, el?: EventTarget & HTMLDivElement) => void;
};

const HistoryItem = ({ order, handleItemClick }: HistoryItemProps) => {
	const [touchStartPoint, setTouchStartPoint] = useState(window.innerHeight);
	const { date, line_items, order_number, statuses, tracking } = order;

	let trackingCopy = '';
	if (tracking && tracking.length) {
		if (tracking.length > 1) {
			const hasCompany = tracking.find(trk => !!trk.tracking_company && trk);
			if (hasCompany) trackingCopy = ` - ${hasCompany.tracking_company}`;
		}
		const defaultCompany = tracking[0]?.tracking_company;
		trackingCopy = defaultCompany ? ` - ${defaultCompany}` : '';
	}

	const statusCopy = statuses.findLast((status: OrderStatusPOMS) => status.completed);
	const mapLineItems = useCallback(items => {
		return items.length
			? items.map(({ removed, product_title, variant_title }, index) => (
					<li key={`${product_title} + ${index}`} className={cn(styles['line-item'], { [styles.removed]: removed })}>
						<Paragraph style={{ display: 'inline' }}>
							{product_title} {determineVariantCopy(variant_title) || ''}
						</Paragraph>
					</li>
				))
			: null;
	}, []);

	return (
		<Flex
			fullWidth
			align='center'
			key={order_number}
			className={styles.item}
			onClick={e => {
				if (e.cancelable) e.preventDefault();
				handleItemClick(order_number, e.currentTarget);
			}}
			onTouchStart={e => {
				setTouchStartPoint(e.touches[0].clientY);
			}}
			onTouchEnd={e => {
				if (e.cancelable) e.preventDefault();

				if (e.changedTouches[0].clientY !== touchStartPoint) return;
				handleItemClick(order_number, e.currentTarget);
			}}
			tabIndex={1}
		>
			<Flex column justify='between' align='start' gap={2} style={{ flexGrow: 1 }}>
				<Heading tag='h6'>{date}</Heading>
				<Paragraph className={styles.status} data-order-status={statusCopy.title}>
					{statusCopy.title}
					<span className={styles.tracking}>{trackingCopy}</span>
				</Paragraph>
				<Paragraph>Order #{order_number}</Paragraph>
				<Flex column gap={2} className={styles['line-item-list']}>
					{mapLineItems(line_items)}
				</Flex>
			</Flex>
			<Chevron direction='right' width={20} height={20} />
		</Flex>
	);
};

export default memo(HistoryItem);
